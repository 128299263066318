<template>
  <div>
    <MobAppDownload/>
    <slot name="content"></slot>
    <div class="wrapper__video">
      <video
        playsinline=""
        poster="~@/assets/video/poppy.jpg"
        autoplay
        muted
        loop
        class="section__tizer-video"
        height="100%"
      >
        <source
          src="~@/assets/video/poppy.webm"
          type="video/webm"
        >
        <source
          src="~@/assets/video/poppy.mp4"
          type="video/mp4"
        >
      </video>
    </div>
  </div>
</template>
<script>
import MobAppDownload from '@/components/base/MobAppDownload'

export default {
  name: 'WrapperAuth',
  components: { MobAppDownload }
}
</script>