<template>
  <div class="section d-flex justify-center align-center">
    <WrapperAuth>
      <template #content>
        <router-view/>
        <BaseSnackList/>
      </template>
    </WrapperAuth>
  </div>
</template>

<script>

import '@/css/authLayout.css'
import BaseSnackList from '@/components/base/BaseSnackList/BaseSnackList'
import meta from '@/components/mixins/meta'
import WrapperAuth from '@/views/public/WrapperAuth'

export default {
  mixins: [meta],
  name: 'AuthLayout',
  components: { BaseSnackList, WrapperAuth }
}
</script>

<style scoped>

</style>
