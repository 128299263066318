<template>
  <div class="download-mob">
    <div class="wrapper-download-mob">
      <h4>{{$t('publicText.mobApp')}}</h4>
      <a href="https://apps.rustore.ru/app/ru.edinayasreda"
         target="_blank">
        <img src="~@/assets/logo-monochrome-light.svg">
      </a>
      <v-btn
        x-small
        outlined
        rounded
        target="_blank"
        href="https://drive.usercontent.google.com/download?id=1C08f_s3TiLTS0y0aygwA_P8_rRfkDifb&export=download&authuser=0"
      >
        <v-icon class="mr-2">mdi-android</v-icon>
        {{$t('publicText.downloadFile')}}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobAppDownload',
}
</script>

<style scoped>
  .download-mob {
    position: absolute;
    padding: 24px;
    background: linear-gradient(217deg, #b9e0ff, #e3fdcf, #ffa9e9, #fde59c);
    background-size: 200% auto;
    animation: gradientAnimation 5s ease infinite;
    top: 12px;
    right: 12px;
    z-index: 2;
    border-radius: 24px;
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.4);
  }
  @keyframes gradientAnimation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .wrapper-download-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>